<template>
  <MainLayout
    isFullScreen
    class="main-layout--signup-artist">
    <img
      v-if="$mediaQueries.isDesktop"
      :src="heroImage"
      alt=""
      class="signup__hero"
    />

    <router-link
      :to="{ name: 'LandingPage' }"
      class="signup__logo">
      <Logo
        :size="$mediaQueries.isDesktop ? 'base' : 'sm'"
        :color="$mediaQueries.isDesktop ? 'white' : 'black'">
      </Logo>
    </router-link>

    <ClientPendingBooking
      v-if="hasPendingBooking"
      :artist="currentArtist"
      :class="{ 'm-md mb-0': !$mediaQueries.isDesktop }">
    </ClientPendingBooking>

    <section class="signup__wrapper">
      <div
        class="signup__header-links">
        <router-link
          :to="{ name: 'ArtistSignup' }"
          class="signup__header-link"
          active-class="signup__header-link--active">
          {{ $t('artist.signin.iAmArtist') }}
        </router-link>
        <router-link
          :to="{ name: 'ClientSignup' }"
          class="signup__header-link"
          active-class="signup__header-link--active">
          {{ $t('artist.signin.iAmBooker') }}
        </router-link>
      </div>
      <div class="signup__header-links">
        <router-link
          :to="{ ...(hasPendingBooking
              ? { name: 'ArtistProfilePublic', params: { id: currentArtist.ID } }
              : { name: 'LandingPage' }
            )
          }"
          class="signup__header-back">
          <Icon
            :variant="'arrow-left-outline'"
            :size="'xxs'"
            class="mr-xs">
          </Icon>
          {{ $t('common.back') }}
        </router-link>
        <template v-if="$mediaQueries.isDesktop">
          <div class="signup__header-back mr-base ml-auto">
            {{ $t('artist.signup.alreadyMember') }}
          </div>
          <router-link :to="{ name: $route.name === 'ArtistSignup' ? 'ArtistSignin' : 'ClientSignin' }">
            <Button
              isWhite
              type="button">
              {{ $t('artist.signin.connect') }}
            </Button>
          </router-link>
        </template>
      </div>
      <header class="signup__header">
        <Heading class="mb-base">
          {{ $t('artist.signup.createAccount') }}
        </Heading>
        <Paragraph class="mr-xxs">
          {{ $t('artist.signup.description') }}
        </Paragraph>
      </header>

      <form
        @submit.prevent="onSubmit"
        class="signup__form"
        ref="form"
        novalidate>
        <FieldInput
          @input="sanitizeMail"
          v-model="signupEmail"
          :label="`${$t('common.email')}*`"
          :placeholder="'email@email.fr'"
          isAutofocus
          id="signup-mail"
          type="email"
          autocomplete="email"
          required
          class="mb-base">
        </FieldInput>
        <FieldInput
          v-model="signupPassword"
          :label="`${$t('common.password')}*`"
          :placeholder="$t('common.password')"
          id="signup-password"
          type="password"
          minlength="8"
          autocomplete="new-password"
          hasRightIcons
          required
          ref="password"
          class="mb-base">
          <template #icons-right>
            <Icon
              @click="$refs.password.$refs.input.type = $refs.password.$refs.input.type === 'password' ? 'text' : 'password'"
              :variant="'view-outline'"
              :tag="'button'"
              :size="'xs'"
              class="ml-xs"
              type="button">
            </Icon>
          </template>
        </FieldInput>
        <Checkbox
          v-model="isAdultAndHasAcceptedCGU"
          :label="$t('artist.signup.conditions')"
          required
          id="tc">
          <template #label>
            <i18n path="artist.signup.conditions" tag="span">
              <template #conditions>
                <router-link
                  :to="{ name: 'ServiceTerms' }"
                  target="_blank"
                  class="signup__tc-link">
                  {{ $t('artist.signup.accept') }}
                </router-link>
              </template>
            </i18n>
            *
          </template>
        </Checkbox>

        <Loader
          v-if="isLoading"
          class="mt-lg">
        </Loader>
        <Button
          v-else
          :isFluid="!$mediaQueries.isDesktop"
          type="submit"
          class="mt-lg">
          {{ $t('artist.signup.createAccount') }}
        </Button>
        <Paragraph
          :size="'sm'"
          class="mt-lg">
          *{{ $t('common.mandatoryField') }}
        </Paragraph>
      </form>

      <div
        v-if="!$mediaQueries.isDesktop"
        class="signup__create-account-mobile">
        <div class="signup__header-back my-base">
          {{ $t('artist.signup.alreadyMember') }}
        </div>
        <router-link :to="{ name: isArtist ? 'ArtistSignin' : 'ClientSignin' }">
          <Button
            isWhite
            type="button">
            {{ $t('artist.signin.connect') }}
          </Button>
        </router-link>
      </div>

      <LegalLinks class="mt-lg"></LegalLinks>
    </section>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
}                               from 'vuex';

import MainLayout               from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Logo                     from '../../../components/atoms/Logo/a-Logo.vue';
import Checkbox                 from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput               from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                   from '../../../components/atoms/Button/a-Button.vue';
import Loader                   from '../../../components/atoms/Loader/a-Loader.vue';
import LegalLinks               from '../../../components/molecules/LegalLinks/m-LegalLinks.vue';
import ClientPendingBooking     from '../../../features/ClientBooking/components/o-ClientPendingBooking.vue';
import ArtistHeroImage          from '../../../../public/assets/images/artiste/signup.jpg';
import ClientHeroImage          from '../../../../public/assets/images/client/signup.jpg';
import { SIGN_UP_MUTATION }     from '../api/queries/q-sign-up.js';
import { SIGN_UP_RESPONSE }     from '../api/responses/r-sign-up.js';

export default {
  name: 'p-Signup',
  components: {
    MainLayout,
    Icon,
    Logo,
    Checkbox,
    Heading,
    Paragraph,
    FieldInput,
    Button,
    Loader,
    LegalLinks,
    ClientPendingBooking,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    signupEmail: '',
    signupPassword: '',
    isAdultAndHasAcceptedCGU: false,
    isLoading: false,
  }),
  computed: {
    ...mapState('SearchArtist', ['currentArtist']),
    ...mapGetters({
      hasPendingBooking: 'SearchArtist/HAS_PENDING_BOOKING',
    }),
    heroImage() {
      return this.isArtist ? ArtistHeroImage : ClientHeroImage;
    },
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setUser: 'User/SET_USER',
    }),
    ...mapActions({
      subscribeToEmailValidation: 'User/SUBSCRIBE_TO_EMAIL_VALIDATION',
    }),
    sanitizeMail(value){
      this.signupEmail = value.toLowerCase().trim();
    },
    async onSubmit() {
      const isFormValid = this.$refs.form.checkValidity();

      if (!isFormValid) return;

      this.setUser({ Email: this.signupEmail }),
      this.isLoading = true;

      try {
        const data = await this.$apollo.mutate({
          mutation: SIGN_UP_MUTATION,
          variables: {
            input: {
              email: this.signupEmail,
              password: this.signupPassword,
              isArtist: this.isArtist,
            },
          },
        });
        const { __typename: responseTypeName, message: ID } = data.data.signup;

        switch(responseTypeName) {
          case SIGN_UP_RESPONSE.operationInformation:
            await this.subscribeToEmailValidation(ID);

            this.isArtist
              ? this.$router.push('/artist/signup/mail-validation')
              : this.$router.push('/client/signup/mail-validation');
            break;

          case SIGN_UP_RESPONSE.userAlreadyExists:
          case SIGN_UP_RESPONSE.wrongInput:
            this.addErrors([{ type: 'alert', message: this.$t(`artist.signup.errors.${responseTypeName}`) }]);
            break;
        }
      } catch(error){
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      }
      finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">

.signup {
  &__hero {
    grid-row: 1 / 2;
    object-fit: cover;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-md);
    padding-bottom: 0;

    @media screen and ($desktop) {
      position: absolute;
      top: 40px;
      left: 10%;
      top: 100px;
      padding: 0;
    }
  }

  &__wrapper {
    display: grid;
    grid-column: 1 / 2;
    grid-template-rows: minmax(70px, auto) auto 175px 1fr;
    padding: var(--space-lg) var(--space-md);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(70px, auto) minmax(70px, auto) 175px 1fr auto;
      max-width: 700px;
      padding: var(--space-xxl);
    }
  }

  &__header-links {
    display: flex;
    align-items: baseline;
  }

  &__header-link {
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    color: var(--color-grey-neutral);

    &:not(:last-of-type) {
      margin-right: var(--space-lg);

      @media screen and ($desktop) {
        margin-right: var(--space-xl);
      }
    }

    &--active {
      text-underline-offset: var(--space-xxs);
      text-decoration: underline;
      color: var(--color-black);
    }
  }

  &__header-back {
    display: flex;
    align-items: baseline;
    font-size: var(--text-sm);
    font-family: var(--font-stack-secondary);
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-self: end;

    @media screen and ($desktop) {
      align-items: flex-start;
      align-self: start;
    }
  }

  &__create-account-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-md) 0;
  }

  &__tc-link {
    font-weight: var(--font-bold);
    text-decoration: underline;
  }
}

</style>
