import gql      from 'graphql-tag';


export const SIGN_UP_MUTATION = gql`
mutation ($input: ConnectionInput!) {
  signup(input: $input) {
    ... on OperationInformation {
      message
      status
    }

    ... on UserAlreadyExist {
      message
      status
    }

    ... on WrongInput {
      errors {
        message
        status
      }
    }
  }
}`